<template>
  <div>
    <crud-table
      ref="crudTable"
      :list-items="listItems"
      :is-editable="false"
      :is-disabled-deleted-filter="false"
      :is-restorable="false"
      :is-deleteble="true"
      :show-brands-filter="true"
      :show-search-filter="true"
      :custom-template="true"
      :brand-list="brandList"
      heading="Secretaries"
      endpoint="secretaries"
      row-click-path="/tables/secretaries"
      @create="createSecretary"
      @edit="editSecretary"
      @delete="deleteSec"
    >
      <template v-slot:items="props">
        <td>
          <a
            :href="`/tables/secretaries/${props.item.id}`"
            class="custom-row-link crud-table-row">
            {{ props.item.id }}
          </a>
        </td>
        <td>
          <a
            :href="`/tables/users/${props.item.master.id}`">
            <span>{{ props.item.master.name }}</span>
          </a>
          <br>
          <b>email: </b><span>{{ props.item.master.email }}</span>
        </td>
        <td>
          <a
            :href="`/tables/users/${props.item.client.id}`">
            <span>{{ props.item.client.name }}</span>
          </a>
          <br>
          <b>email: </b><span>{{ props.item.client.email }}</span>
        </td>
        <td>
          <a
            :href="`/tables/secretaries/${props.item.id}`"
            class="custom-row-link crud-table-row">
            {{props.item.brand.name}}
          </a>
        </td>
        <td>
          <a
            :href="`/tables/secretaries/${props.item.id}`"
            class="custom-row-link crud-table-row">
            {{props.item.status}}
          </a>
        </td>
        <td>
          <a
            :href="`/tables/secretaries/${props.item.id}`"
            class="custom-row-link crud-table-row">
            {{props.item.invitedAt}}
          </a>
        </td>
      </template>
    </crud-table>

    <edit-dialog
      :show.sync="editDialog.display"
      :max-width="'600'"
      @dialogConfirm="editDialog.saveFunction">
      <template>
        <v-container grid-list-xl>
          <v-layout
            row
            wrap>
            <v-flex
              xs12
              sm6>
              <v-select
                v-if="brandList.length"
                :items="brandList"
                v-model="editDialog.data.brand"
                :item-text="(val) => { return val.name }"
                item-value="id"
                label="Brand"
              />
            </v-flex>
            <v-flex
              v-if="editDialog.type != 'edit'"
              xs12
              sm6>
              <users-input
                :disabled="!editDialog.data.brand"
                v-model="editDialog.data.master"
                :brand-list="[editDialog.data.brand]"
                :query-params="{ params: { role: 'ROLE_PASSENGER' } }"
                label="Secretary"
                @changeUser="(inputUser) => { editDialog.data.master = inputUser.id }" />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <users-input
                :disabled="!editDialog.data.brand"
                v-model="editDialog.data.client"
                :brand-list="[editDialog.data.brand]"
                :query-params="{ params: { role: 'ROLE_PASSENGER' } }"
                label="Customer"
                @changeUser="(inputUser) => { editDialog.data.client = inputUser.id }" />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-select
                :items="['active', 'inactive']"
                v-model="editDialog.data.status"
                label="Status"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </edit-dialog>
  </div>
</template>

<script>
import CrudTable from '../../../modules/admin/CrudTable.vue'
import Request from '../../../helpers/request'
import Brands from '../../../helpers/brands'
import { TEXT_FIELD_MAX_LENGTH } from '../../../constants/common'
import Secretary from '../../../helpers/secretary'
import UsersInput from '../../../modules/components/UsersInput.vue'

export default {
  components: { CrudTable, UsersInput },
  mixins: [Request, Brands, Secretary],
  data () {
    return {
      valid: true,
      brandList: [],
      listItems: [
        { text: 'ID', value: 'id' },
        { text: 'Secretary', value: 'master' },
        { text: 'Customer', value: 'client' },
        { text: 'Brand', value: 'brand', textValue: 'name', sortable: false },
        { text: 'Status', value: 'status' },
        { text: 'Invited at', value: 'invitedAt' },
        { text: '', value: null }
      ],
      TEXT_FIELD_MAX_LENGTH,
      loading: true,
      data: [],
      editDialog: {
        display: false,
        saveFunction: () => {},
        data: {}
      }
    }
  },
  async beforeMount () {
    try {
      this.brandList = await this.fetchBrands()
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    createSecretary () {
      this.editDialog.display = true
      this.editDialog.data = { }
      this.editDialog.type = 'create'

      this.editDialog.saveFunction = async () => {
        try {
          let body = {
            data: {
              'master': this.editDialog.data.master,
              'client': this.editDialog.data.client,
              'status': this.editDialog.data.status,
              'brand': this.editDialog.data.brand
            }
          }

          await this.request('POST', `/secretaries`, body)

          this.editDialog.display = false
          this.$refs.crudTable.requestData()
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Secretary has been created'
          })
        } catch (error) {

        }
      }
    },
    editSecretary () {},
    async deleteSec (id) {
      try {
        await this.deleteSecretary(id)

        this.$refs.crudTable.requestData()
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Secretary has been deleted'
        })
      } catch (error) {

      }
    }
  }
}
</script>

<style lang="scss">
  .client-header {
    color: rgb(0, 175, 131) !important;
  }

  .secretary-header {
    color: rgb(215, 129, 0) !important;
  }

  .custom-row-link {
    width: 100%;
    height: 100%;
    display: flex;
    color: inherit;
    align-items: center;
  }

  .crud-table-row {
    max-height: 120px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
</style>
